@import '../colors.scss';

.social-media-section{
    display: flex;
    justify-content: center;
    //align-items: center;
    background-color: $gray5-transparent;
    height: 120px;
    color: $light-gray-3;
    li{
      list-style-type: none;
      padding: 16px 16px;
      display:flex;
      align-items: center;
    }
    ul{
      display: flex;
      margin: 0;
      flex-direction: row;
    }
  }