@import '../colors.scss';

.work-title-photo-wrapper {
    img{
        height: 350px;
        border-radius: 14px;
    }
}

.work-title-text-wrapper{
    h1{
        font-size: 100px;
        padding-bottom: 14px;
        font-family: "Nunito", sans-serif;

    }
}

.company-logo-wrapper{
    display: flex;
    align-items: center;
    img{
        height: 200px;
    }
}

.phoenix{
    padding-right: 0px !important;
}

.bloons-pic{
    img{
        height: 150px;
    }
}

.phoenix-pic{
    img{
        height: 170px;
    }
}
.job-title-wrapper{
    display: flex;
    justify-content: space-between;
}

.experience-description-text-wrapper{
    color: $gray5;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    .experience-title-text{
        font-size: 30px;
        .dates{
            font-size: 20px;
            font-weight: 200;
            font-style: italic;
            color: $orange1;
        }
    }
    .company-text{
        color: $orange1;
        font-size: 40px;
    }
    .experience-description-text {
        font-weight: 200;
        font-size: 16px;
        &.bolded-text{
            font-weight: 600;
        }
    }
    .experience-description-text{
        a{
            color: inherit;
            &:hover{
                color: $orange1;
            }
        }
    }
}

.mobile{
    .work-title-text-wrapper{
        h1{
            font-size: 80px;
        }
        &.real-mobile{
            h1{
                font-size: 60px;
            }
        }
    }
    .work-title-photo-wrapper{
        img{
            height: 250px;
        }
        &.real-mobile{
            img{
                height: 220px;
            }
        }
    }
    .company-logo-wrapper{
        img{
            height: 150px;
        }
    }
}
