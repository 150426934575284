@import '../colors.scss';


.footer-container{
    .wide-nav-content-box-container{
        padding-left:8px;
        padding-right: 8px;
    }
    &.mobile-footer{
        ul{
            li{
                padding: 14px 6px;
            }
        }
    }
    padding: 24px;
    padding-bottom: 40px;
    background-color: $light-gray-3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .wide-nav-content-box-container{
        justify-content: center;
        li {
            padding: 14px 14px;
            a {
                color: $gray5;
                &:hover{
                    color: $orange1;
                }
              }
        }
    }

    .footer-stamp {
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: $gray5-transparent;
    }
}

