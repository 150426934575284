$light-gray-1: #c0cad6;
$light-gray-2: #adc0cd;
$light-gray-3: #e2eaec;
$light-gray-4: #9ea9bd;
$light-gray-5: #d3dfdf;
$light-gray-6: #d6dad9;

$gray1: #dddeef;
$gray2: #c3c3c3;
$gray3: #7c8594;
$gray4: #2b3d5b;
$gray5: #15253f;
$gray6: #0e1d35;

$gray5-transparent: #15253fda;
$light-gray-3-transparent: #e2eaecbd;

$orange1: rgb(212, 172, 86);
$light-orange1: rgba(212, 172, 86, 0.761);