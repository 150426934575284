@import '../colors.scss';

@import '../colors';

.drop-down-header-container {
    display: flex;
    padding: 20px 30px;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    background-color: $light-orange1;
    border: 2px solid $gray5;

    .subtitle {
        font-size: 60px;
        color: $gray5;
    }
    &:hover{
        background-color: $light-orange1;
    }

    .arrow-icon-container{
        .carrot-icon{
            height: 50px;

            &.opened{
                transform: rotate(180deg);
            }
        }
    }
}

.mobile-drop-down{
    .drop-down-header-container{
        .subtitle{
            font-size: 40px;
        }
    }

    &.real-mobile-drop-down{
        .drop-down-header-container{
            .subtitle{
                font-size: 30px;
            }
        }
    }

}