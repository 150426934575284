@import '../colors.scss';

.ysanne-photo-wrapper{
    img{
        height: 500px;
        border-radius: 14px;
    }
}
.canyon-photo-wrapper{
    img{
        height: 500px;
        border-radius: 14px;
    }
}


.about-me-title{
    font-size: 140px;
    color: $orange1;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
}

.name-header {
    font-size: 80px;
    font-family: "Nunito", sans-serif;
    font-weight: 200;

    &.mobile{
        text-align: center;
        padding-bottom: 20px;
    }
}
.about-me-content-one-wrapper{
    .about-me-content{
        font-family: "Nunito", sans-serif;
        font-weight: 200;
        font-size: 20px;
        padding-bottom: 20px;
    }
}

.wake-surf-photo-wrapper{
    img{
        height: 400px;
        border-radius: 14px;
    }
}

.first-day-photo-wrapper{
    img{
        height: 500px;
        border-radius: 14px;
    }
}
.photo-caption{
    text-align: center;
    font-family: "Nunito", sans-serif;
    font-weight: 200;
    font-size: 12px;
    padding-top: 8px;
}


// mobile stuff\

.mobile{
    .about-me-title-wrapper{
        .about-me-title{
            font-size: 80px;
        }
        .name-header{
            font-size: 60px;
        }
        &.real-mobile{
            .about-me-title{
                font-size: 70px;
            }
            .name-header{
                font-size: 40px;
            }
        }
    }

    .canyon-photo-wrapper{
        img{
            height: 350px;
        }
    }
    .ysanne-photo-wrapper{
        display: flex;
        flex-direction: column;
        img{
            height: 350px;
            align-self: center;
        }
    }
    .photo-caption{
        font-size: 12px;
    }

    .subtitle{
        font-size: 40px;
    }
    .about-me-content{
        font-size: 16px;
    }

    .wake-surf-photo-wrapper{
        display: flex;
        flex-direction: column;
        img{
            height: 250px;
            align-self: center;
        }
    }

    .first-day-photo-wrapper{
        img{
            height: 400px;
        }
    }
}