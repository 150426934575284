@import '../colors';

.interests-main-photo{
    img{
        height: 500px;
    }
    .artist-caption{
        padding-left: 18px;
        font-size: 12px;
        padding-top: 10px;
    }
}

.interests-title{
    h1{
        font-size: 140px;
        font-family: "Nunito", sans-serif;
        font-weight: 600;

    }

    h2{
        font-size: 80px;
        font-family: "Nunito", sans-serif;
        font-weight: 200;
    }

}

.fishing-photo{
    img{
        height: 400px;
        border-radius: 14px;
    }
}

.content-description-text{
    p{
        font-family: "Nunito", sans-serif;
        font-weight: 200;
        font-size: 20px;
    }
}

.food-photo{
    img{
        height: 350px;
        border-radius: 14px;
    }
}

.performance-photo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        height: 300px;
        border-radius: 14px;
        align-self: center;
    }
}

.mobile{
    .interests-main-photo{
        img{
            height: 350px;
        }
    }

    .fishing-photo{
        img{
            height: 300px;
        }
        &.real-mobile{
            img{
                height: 225px;
            }
        }
    }

    .performance-photo{
        img{
            height: 225px;
        }
        &.real-mobile{
            img{
                height: 200px;
            }
        }
    }
    .food-photo{
        &.real-mobile{
            img{
                height: 250px;
            }
        }
    }
    .interests-title{
        h1{
            font-size: 100px;
        }

        h2{
            font-size: 60px;
        }
        &.real-mobile{
            h1{
                font-size: 80px;
            }
            h2{
                font-size: 40px;
            }
        }
    }

    .content-description-text{
        p{
            font-size: 16px;
        }
        .subtitle{
            font-size: 30px;
        }
    }

}