@import '../colors.scss';

.navbar {
  width: 100%;
  overflow: hidden;
  background-color: $gray5-transparent;
}

.nav-content-box-wrapper{
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;

  li {
    list-style-type: none;
    padding: 14px 20px;
  }
  a {
    display: block;
    color: $gray1;
    text-decoration: none;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 18px;

    &:hover {
      color: $orange1;
    }
  }
}

.mobile-nav-content-box-container{
  padding: 20px 0px;
  .burger-icon-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    img{
      height: 40px;
      cursor: pointer;
    }
  }
  a {
    text-align: left;
  }
}

.wide-nav-content-box-container {
  display: flex;
  justify-content: flex-end;
  padding-left: 15px;
  padding-right: 8px;
  padding-bottom: 12px;
  padding-top: 12px;

  a{
    &.selected{
      color:$orange1;
    }
    text-align: center;

    padding-bottom:2px;
    background-image: linear-gradient($orange1, $orange1);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
    &:hover {
      color: $orange1;
      background-position: 100% 100%; /*OR bottom right*/
      background-size: 100% 2px;
    }
  }

  & > ul {
    display: flex;
    margin: 0;
    &.nav-content-box {
      justify-content: flex-start;
    }
  }
}

.fa {
  border-radius: 50%;
}

.social-media-container {
  display: block;
}
