@import "../colors";

.content-section {
    color: $gray5;
    padding: 20px 0px;

    &.in-view{
      background-color: $gray5-transparent;
      color: $gray1;
    }

    .main-section-container {
      width: 1170px;
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;

      &.mobile{
        flex-direction: column;
        align-items: center;

      & > div {
          padding: 10px 30px;
      }

      }

      & > div {
        padding: 20px 50px ;
      }
    }
  }

  .mobile{
    h1{
      padding-top: 0px;
    }
  }