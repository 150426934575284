@import '../colors.scss';

.learn-more-button {
    cursor: pointer;
    height: 30px;
    background-color: $orange1;
    border:none;
    border-radius: 20px;


    span{
        padding: 14px;
        font-size: 24px;
        font-family: "Nunito", sans-serif;
        font-weight: 600;
        color: $gray6;
    }
}