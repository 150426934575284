@import './colors.scss';

.application-container{
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
}
html {
  background-color: $light-gray-3;
}

body{
  background-image: url(../images/background-bricks-light.svg);
  background-size: cover;
  background-position-y: center;
  background-attachment: fixed;
}
.drop-down-nav{
  position: absolute;
  width: 100%;

  li {
    background-color: $gray5-transparent;
    list-style-type: none;
    padding: 14px 20px;
    border-bottom: 1px solid $light-gray-3;

    &:last-child{
      border: none;
    }
  }

  a {
    display: block;
    color: $gray1;
    text-decoration: none;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 18px;

    &:hover {
      color: $orange1;
    }
  }
}

