@import '../colors.scss';

.page-container {
  display: block;
  width: 100%;
}

.profile-photo {
  display: flex;
  justify-content: flex-end;
  img {
    height: 500px;
    border-radius: 15px;
  }
}
.title-section {
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 100px;
  min-width: 180px;
  h1{
    padding-top: 80px;
    font-size: 100px;
    font-family: "Nunito", sans-serif;
  }
  p{
    font-family: "Nunito", sans-serif;
    font-weight: 200;
    font-size: 20px;
    max-width: 500px;
    padding-top: 12px;
  }
}

 // un comment this to change css for mobile stuff.
.mobile{
  .title-section{
    h1{
      font-size: 70px;
    }
    .subtitle{
      font-size: 40px;
    }

    &.real-mobile{
      h1{
        text-align: center;
        font-size: 60px;
      }
      .subtitle{
        text-align:center;
        font-size: 30px;
      }
    }
  }
  .profile-photo{
    img{
      height: 300px;
    }
  }

  .about-me{
    .personal-blurb{
      font-size: 16px;
    }
    .subtitle{
      font-size: 30px;
    }
  }

}

.side-profile{
  height: 100%;
  padding-top: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  img{
    height: 350px;
    border-radius: 15px;
  }
}

.about-me{
  font-size: 20px;
  padding-top: 30px;
  font-family: "Nunito", sans-serif;
  font-weight: 200;
  padding-bottom: 20px;
  .personal-blurb{
    padding-bottom: 20px;
    a{
      color: inherit;
      &:hover{
        color: $orange1;
      }
    }
  }
}

.subtitle{
  font-size: 50px;
  font-family: "Nunito", sans-serif;
  font-weight: 200;
  color: $orange1;
}

.mobile{
  .side-profile{
    img{
      height: 250px;
    }
    &.real-mobile{
      img{
        height: 200px !important;
      }
    }
  }
}